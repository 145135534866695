import { isValidPhoneNumber } from 'libphonenumber-js';
import { validate } from 'email-validator';

export const required = (value: string | null) => (value ? undefined : 'This field is required');

export const optional = (validator: (value: string) => string | undefined) => (value: string | null) =>
  value ? validator(value) : undefined;

export const phoneNumber = (value: string | null) =>
  value && isValidPhoneNumber(value.replace(/^tel:/, ''), 'US') ? undefined : `Invalid phone number`;

export const email = (value: string | null) => (value && validate(value) ? undefined : 'Invalid email address');

export const postalCode = (value: string | null) =>
  value && /^\d{5}(?:-\d{4})?$/.test(value) ? undefined : 'Invalid postal code';
